import React from 'react'
import { withPrefix } from 'gatsby'
import siteConfig from '../../../data/siteConfig'
import styled from 'styled-components'

const HeroContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  overflow: hidden;
`

const TitleContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
`

const HeroTitle = styled.h1`
  font-weight: 700;
  font-size: 2.199rem;
  margin: 0.5rem;
`

const Hero = ({ className, title }) => (
  <HeroContainer className={className}>
    <TitleContainer>
      <HeroTitle>{title}</HeroTitle>
    </TitleContainer>
  </HeroContainer>
)

export default styled(Hero)`
  height: 25vh;
`
