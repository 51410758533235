module.exports = {
  siteTitle: 'Senior SEO',
  siteDescription: 'CV Riad Joseph - SEO',
  keyWords: ['SEO Manager', 'Référencement naturel', 'Search Engine Optimisation'],
  authorName: 'Riad Joseph',
  authorPhone: '+33(6)29 15 98 58',
  twitterUsername: 'riadjosephs',
  githubUsername: 'riadjoseph',
  authorAvatar: '/images/Riad-Joseph-SEO-Profile-natureBG.jpg',
  authorDescription: '<p>Professionnel du web depuis 2008 pour de grands comptes, PMEs et start-ups, je rends le SEO et ses défis techniques faciles à comprendre pour les responsables, dirigeants et entrepreneurs. Je guide les équipes techniques dans la mise en œuvre et l’exécution de la stratégie.</p> <h4>But</h4><p>🎯  Rejoindre une équipe comme SEO Manager ou Head of SEO. Chez l’annonceur ou en agence. </p> <h4>Compétences soft</h4><p>➡️ International, Gestion de projet, Relation clients, Trilingue.</p> <h4>Réalisations clés</h4><p>🥇 Clients e-commerce en 1er rank, sur mots-clés de fortes et moyennes volumétries menant à un taux de conversion en courbe ascendante (Secteurs Beauté, Tourisme), 🥈 Gestion SEO International sur des sites larges, 🥉 Top 5 rank sans campagnes backlinks (mots-clés de moyenne volumétrie, mais concurrents poids lourds), ✨ Travail pour et en collaboration avec des artistes et designers de renommée internationale.</p>',
  skills: [
    {
      name: 'HTML/CSS',
      level: 90
    },
    {
      name: 'Google Search Console',
      level: 90
    },
    {
      name: 'Strategy',
      level: 90
    },
    {
      name: 'Reporting',
      level: 90
    },
    {
      name: 'KW Research',
      level: 85
    },
    {
      name: 'Tech SEO',
      level: 90
    },
    {
      name: 'Crawl Analysis',
      level: 85
    },
    {
      name: 'Cloud Console',
      level: 75
    },
    {
      name: 'Outreach',
      level: 70
    },
    {
      name: 'Log Files',
      level: 85
    },
    {
      name: 'Tools (ahrefs, etc)',
      level: 85
    },
    {
      name: 'CMS',
      level: 85
    },
    {
      name: 'Analytics & Tagging',
      level: 80
    },
    {
      name: 'Site Migration',
      level: 85
    },
    {
      name: 'Python/Puppeteer',
      level: 40
    },
    /* more skills here */
  ],
  jobs: [
    /* more jobs here */
    {
      company: "WebForGood.fr",
      begin: {
        month: '2018',
        year: 'France'
      },
      duration: null,
      occupation: "Consultant SEO Auto-Entreprise",
      description: "Conseils et mise en œuvre, SEO de contenu et technique, on-site / off-site, auprès des PMEs et agences."

    },  {
      company: "HolidayMe.com",
      begin: {
        month: '2015',
        year: 'Dubai'
      },
      occupation: "Manager of Strategic Alliances, Arabic Localisation, SEO & Marketing",
      description: "+30% de croissance mensuelle, Manager d'équipe de rédacteurs, marketeurs et spécialiste outreach, Finaliste meilleure startup Émirats Arabes Unis 2016,  Forte volumétrie."

    },  {
      company: "TravelerVIP.com",
      begin: {
        month: '2014',
        year: 'Dubai'
      },
      duration: null,
      occupation: "Directeur Co-Fondateur",
      description: " Responsable d'une équipe de 13 personnes, +120% de ventes année sur année. ",
      TravelerVIP: "https://www.youtube.com/watch?v=VBcgl6_Uq3k"
    }, {
      company: "Cobone.com",
      begin: {
        month: '2011',
        year: 'Dubai'
      },
     /* duration: '1 yr e 5 mos', */
      occupation: "Travel Product Owner",
      description: "Plus de 50,000 nuitées l'année, Gestion de plus 1 million € ventes, Étroite collaboration avec les équipes SEO / SEA / Contenu et Développeurs web, Bilingue anglais / arabe, Forte volumétrie."
    }, {
      company: "Booking .com",
      begin: {
        month: '2007',
        year: 'Dubai'
      },
      /*duration: '4 yrs e 3 mos',*/
      occupation: "Manager Middle East Hotels (5 Pays)",
      description: "Croissance jusqu'à 350% des ventes année sur année, Élargissement de l'inventaire x3 le concurrent principal, Multiples formations SEO, E-commerce, et Contenu."
    },
  ],
  portfolio: [
    {
      title: " 3x trafic, 2x conversion ",
      description: "Ecommerce produits high-tech: Stratégie de contenu, Données Structurées, Campagne de netlinking, Maillage interne ",
      image: "/images/Client-Tech-Ecommerce.png",
      url: "/images/Client-Tech-Ecommerce.png"
    },
    {
      title: " #1 ranking pour sa niche sur +200 mots-clés hors marque ",
      image: "/images/Client-beauté.png",
      description: "Ecommerce produits de beauté bio: Stratégie de contenu rédaction et branding, Campagne de netlinking, Maillage interne",
      url: "/images/Client-beauté.png"
    },
    {
      title: " x2 trafic, Page #1 sur produits les plus rentables hors marque ",
      image: "/images/client-industriel.png",
      description: "Client niche industrielle : SEO technique et de contenu, Maillage interne",
      url: "/images/client-industriel.png"
    },
    {
      title: " x7 trafic, + 1.1% CTR ",
      image: "/images/client-music-lessons.png",
      description: "Site vitrine, niche cours de musique : SEO technique et de contenu, Maillage interne",
      url: "/images/client-music-lessons.png"
    },
    {
      title: "Tagging et Tracking GTM, Analytics, Conversion API ",
      image: "/images/tagging-tracking.png",
      description: "Tunnel de vente, strategie et exécution du suivi des tags and pixels.",
      url: "/images/tagging-tracking.png"
    },
    {
      image: "/images/etude-mot-cles-ngrams-oncrawl.png",
      description: "Etude keywords pour nGrams",
      url: "/images/etude-mot-cles-ngrams-oncrawl.png"
    },
    {
      image: "/images/etude-log-files-statusCode.png",
      description: "Etude log files pour Status Code Report",
      url: "/images/etude-log-files-statusCode.png"
    },
    {
      image: "/images/etude-log-files-oncrawl.png",
      description: "Etude log files pour Crawl Report",
      url: "/images/etude-log-files-oncrawl.png"
    },
    {
      image: "/images/GSC-bulk-removal-chrome-extension.png",
      description: "Dev outil de bulk removal de liens pour la GSC comme extension Chrome (adapté de GitHub)",
      url: "/images/GSC-bulk-removal-chrome-extension.png"
    },
    {
      image: "/images/outil-NodeJS-indexation.png",
      description: "Dev outil d'indexation avec NodeJS via l'API de Google Cloud Console (adapté de GitHub)",
      url: "https://webforgood.fr/blog/tutoriel-google-indexing-api-nodejs/"
    },
    {
      image: "/images/shopify-scraper.png",
      description: "Dev outil de scraping pour les prix produits des concurrents sur Shopify (adapté de GitHub)",
      url: "/images/shopify-scraper.png"
    },
    {
      image: "/images/outil-test-performance-en-masse.png",
      description: "Dev outil de test de performance de plusieurs pages avec itérations (adapté de GitHub)",
      url: "https://webforgood.fr/blog/test-performances/"
    },
    {
      image: "/images/Nettoyage-Hack-Japanais-URL-spam.png",
      description: "Nettoyage WordPress touché par le hack par mots-clés japonais (+40,000 URLs)",
      url: "/images/Nettoyage-Hack-Japanais-URL-spam.png"
    },
    {
      image: "/images/strutured-data-products.jpg",
      description: "Corrections et création de données structurées format JSON-LD pour ecommerces (Shopify et PrestaShop)",
      url: "/images/strutured-data-products.jpg"
    },
    {
      image: "/images/outil-obfuscation-lien.png",
      description: "Obfuscation de liens avec R / Php",
      url: "/images/outil-obfuscation-lien.png"
    },
    {
      description: "Plus sur moi en 6 cartes",
      url: "https://cv.webforgood.fr/details.html"
    },
    {
      description: "Téléchergez mon CV (5 pages)",
      url: "https://cv.webforgood.fr/"
    },
    /* more portfolio items here */
  ],
  social: {
    twitter: "https://twitter.com/riadjosephs",
    linkedin: "https://www.linkedin.com/in/riadjoseph",
    github: "tel:0033629159858",
    email: "riadjoseph@icloud.com",
  },
  siteUrl: 'https://riadjoseph.vercel.app/',
  pathPrefix: '/', // Note: it must *not* have a trailing slash.
  siteCover: null,
 // googleAnalyticsId: 'UA-000000000-1',
  background_color: '#ffffff',
  theme_color: '#24292e',
  fontColor: "#000000cc",
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/contact-riad-joseph-SEO.svg',
  headerLinks: [
    {
      label: 'CV',
      url: '/',
    },
    {
      label: 'APERÇU TRAVAIL',
      url: '/portfolio',
    }
  ]
}